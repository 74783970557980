<template>
  Logged in as: {{ teacherName }}
  <br><br>
  <div class="container">
    <h2>Homework Records</h2>
    <div class="row justify-content-center">
      <router-link class="list-group-item list-group-item-action col-8 col-lg-3" to="/hwk/entry">Enter Homework Record</router-link><br>
      <router-link class="list-group-item list-group-item-action col-8 col-lg-3" to="/hwk/classsummary">Class Summary</router-link><br>
      <router-link class="list-group-item list-group-item-action col-8 col-lg-3" to="/hwk/totalcount">Total Count Summary</router-link><br>
      <router-link class="list-group-item list-group-item-action col-8 col-lg-3" to="/hwk/viewall">View All Homework Records</router-link><br>
    </div>
    <div v-if="teacherName == 'kmmak' || teacherName == 'khng' || teacherName == 'kcyuen' || teacherName == 'myko'">
      <br>
      <b>Note:</b> Sections below are available for certain staff only, for testing purpose.
      <br>

      <h2>Conduct Records</h2>
      <div class="row justify-content-center">
        <router-link class="list-group-item list-group-item-action col-8 col-lg-4" to="/dcp/late">Enter Late Record</router-link><br>
        <router-link class="list-group-item list-group-item-action col-8 col-lg-4" to="/dcp/conduct">Enter Other Conduct Record</router-link><br>
        <router-link class="list-group-item list-group-item-action col-8 col-lg-4" to="/dcp/viewall">View All Conduct Records</router-link><br>
      </div>
      <br>
      <h2>ECA Records</h2>
      <div class="row justify-content-center">
        <router-link class="list-group-item list-group-item-action col-8 col-lg-6" to="/eca/entry">Enter ECA Record</router-link><br>
        <router-link class="list-group-item list-group-item-action col-8 col-lg-6" to="/eca/viewall">View All ECA Records</router-link><br>
      </div>
    </div>
    <br>
    <center><router-link class="list-group-item list-group-item-action col-3 logoutbtn" to="/logout">Log Out</router-link></center>
  </div>
</template>

<script>
import DataService from '../database-handler'

export default {
  name: "MainView",

  data () {
    return {
      teacherName: ''
    }
  },

  beforeMount () {
    DataService.checkLogin({ token: sessionStorage.getItem("token") })
      .then(response => {
        this.teacherName = response.data[0]["Teacher"]
      })
      .catch(error => {
        console.log(error);
        sessionStorage.removeItem("token");
        this.$router.push('/login');
      })

  },
}
</script>

<style scoped>
  .logoutbtn {
    color: red;
    background-color: rgb(255, 210, 210);
  }
  .logoutbtn:hover {
    background-color: rgb(255, 170, 170);
  }
</style>
