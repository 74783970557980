import http from './http-common'

class DataService {
// General
  updateToken (username, data) {
    return http.put(`/userupdate/${username}`, data)
  }
  checkLogin (token) {
    return http.post(`/userstatus`, token)
  }
  getClassData (cls) {
    return http.get(`/classinfo/${cls}`)
  }
  getSubjList (form) {
    return http.get(`/formsubj/${form}`)
  }
  /* getSubjAbbr (form) {
    return http.get(`/formabbr/${form}`)
  } */

// Homework Related
  submitHwkRecord (data) {
    return http.post(`/hwk/update`, data)
  }
  getClassHwkSummary (cls) {
    return http.get(`/hwk/summary/${cls}`)
  }
  getTotalCountSummary () {
    return http.get(`/hwk/totalcountsummary`)
  }
  getAllHwkRecords () {
    return http.get(`/hwk/allrecords`)
  }
  getClassHwkRecords (cls) {
    return http.get(`/hwk/classrecords/${cls}`)
  }

// Student Homework
  getStudentHwkRecords (sid) {
    return http.get(`/studenthwk/${sid}`)
  }
  getParentHwkRecords (hkid) {
    return http.get(`/parenthwk/${hkid}`)
  }

// Conduct Related
  submitLateRecord (data) {
    return http.post(`/late/update`, data)
  }
  getConductItems () {
    return http.get(`/conduct/items`)
  }
  submitConductRecord (data) {
    return http.post(`/conduct/update`, data)
  }
  getAllConductRecords () {
    return http.get(`/conduct/allrecords`)
  }
// ECA Related
  submitEcaRecord (data) {
    return http.post(`/eca/update`, data)
  }
  getAllEcaRecords () {
    return http.get(`/eca/allrecords`)
  }
  getClassEcaRecords (cls) {
    return http.get(`/eca/classrecords/${cls}`)
  }
}

export default new DataService()
