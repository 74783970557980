<template>
  <h2>Class Summary ({{ schYear }} Term {{ termNo }})</h2>
  <br>
  <router-link to="/">&lt;&lt; Back to Main</router-link>
  <br><br>
  <label for="selectClass">Choose a class:</label>
  <select name="selectClass" v-model="selectedCls">
    <option value="1A">1A</option><option value="1B">1B</option><option value="1C">1C</option><option value="1D">1D</option><option value="1E">1E</option>
    <option value="2A">2A</option><option value="2B">2B</option><option value="2C">2C</option><option value="2D">2D</option><option value="2E">2E</option>
    <option value="3A">3A</option><option value="3B">3B</option><option value="3C">3C</option><option value="3D">3D</option><option value="3E">3E</option>
    <option value="4A">4A</option><option value="4B">4B</option><option value="4C">4C</option><option value="4D">4D</option><option value="4E">4E</option>
    <option value="5A">5A</option><option value="5B">5B</option><option value="5C">5C</option><option value="5D">5D</option><option value="5E">5E</option>
    <option value="6A">6A</option><option value="6B">6B</option><option value="6C">6C</option><option value="6D">6D</option><option value="6E">6E</option>
  </select>
  <br><br>
  <button type="button" name="summarybutton" class="btn btn-primary" v-on:click="showClassHwkSummary(selectedCls)">Show Class Summary</button>
  <br><br>

  <div class="col" v-if="confirmedCls">
    <table class="center table table-hover table-sm table-bordered">
      <thead class="thead-light">
        <tr>
          <th v-for="(item, index) in responseData[0]" :key="index">{{ index }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in responseData" :key="index">
          <td v-for="(value, index) in item" :key="index">{{ value ? value : 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  
</template>

<script>
import DataService from '../database-handler'

export default {
  name: 'SummaryView',

  data () {
    return {
      schYear: String(this.$schYear).slice(0,2) + '-' + String(this.$schYear).slice(2,4),
      termNo: this.$termNo,
      responseData: [],
      teacherName: '',
      selectedCls: '',
      errMsg: '',
      confirmedCls: false
    }
  },

  methods: {
    showClassHwkSummary (cls) {
      if (cls != '') {
        DataService.getClassHwkSummary(cls)
          .then(response => {
            this.responseData = response.data
          })
          .catch(error => console.log(error))
        this.confirmedCls = true
      }
    }
  },

  beforeMount () {
    DataService.checkLogin({ token: sessionStorage.getItem("token") })
      .then(response => {
        this.teacherName = response.data[0]["Teacher"]
      })
      .catch(error => console.log(error))
  },
}
</script>

<style>
  .center {
    margin-left: auto;
    margin-right: auto;
  }
</style>