<template>
  <h2>Enter Other Conduct Record ({{ schYear }} Term {{ termNo }})</h2>
  <br>
  <router-link to="/">&lt;&lt; Back to Main</router-link>
  <br><br>
  <div v-if="!responseMsg">
    Logged in as: {{ teacherName }}
    <br><br>

    <div v-if="!confirmedCls">
      <label for="selectClass">Choose a class:</label>
      <select name="selectClass" v-model="selectedCls">
        <option value="1A">1A</option><option value="1B">1B</option><option value="1C">1C</option><option value="1D">1D</option><option value="1E">1E</option>
        <option value="2A">2A</option><option value="2B">2B</option><option value="2C">2C</option><option value="2D">2D</option><option value="2E">2E</option>
        <option value="3A">3A</option><option value="3B">3B</option><option value="3C">3C</option><option value="3D">3D</option><option value="3E">3E</option>
        <option value="4A">4A</option><option value="4B">4B</option><option value="4C">4C</option><option value="4D">4D</option><option value="4E">4E</option>
        <option value="5A">5A</option><option value="5B">5B</option><option value="5C">5C</option><option value="5D">5D</option><option value="5E">5E</option>
        <option value="6A">6A</option><option value="6B">6B</option><option value="6C">6C</option><option value="6D">6D</option><option value="6E">6E</option>
      </select>
      <br><br>
      <button type="button" name="showbutton" class="btn btn-primary" :disabled=confirmedCls v-on:click="showStudents(selectedCls)">Confirm Class</button>
    </div>

    <div v-if="confirmedCls">
      <h4>Class: {{ selectedCls }}</h4>
      <button type="button" name="resetbutton" class="btn btn-outline-secondary" v-on:click="resetCls">Reset Class</button>
      <br><br>

      <label for="conducttype">Choose a conduct type:</label>
      <select v-model="selectedConductType" id="conducttype">
        <option value="Good Conduct">Good Conduct</option>
        <option value="Merit">Merit</option>
        <option value="Credit">Credit</option>
        <option value="Honour">Honour</option>
        <option value="Misconduct">Misconduct</option>
        <option value="Warning">Warning</option>
        <option value="Demerit">Demerit</option>
        <option value="Minor Dishonour">Minor Dishonour</option>
        <option value="Major Dishonour">Major Dishonour</option>
        <option value="Dismissal">Dismissal</option>
      </select>
      <br>
      <label for="conductitem">Choose a item:</label>
      <select v-model="selectedConductItem" id="conductitem" class="col-8 col-md-6 col-xl-4">
        <option v-for="(item, index) in conductItemsList" :key="index" :value="item.RecordCode">{{item.ItemName}}</option>
      </select>
      
      <div class="row justify-content-center">
        <div class="col col-md-10 col-lg-8">
          <table class="center table table-hover table-sm table-bordered">
            <thead class="thead-light">
              <tr>
                <th>Class</th>
                <th>Class No.</th>
                <th>English Name</th>
                <th>Chinese Name</th>
                <th>Checkbox</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in clsData" :key="index">
                <td>{{ item.Class }}</td>
                <td>{{ item.ClassNum }}</td>
                <td>{{ item.EngName }}</td>
                <td>{{ item.ChiName }}</td>
                <td><input type="checkbox" :id="item.Class+item.ClassNum" :value="item.ClassNum" v-model="checkedNum" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
        <p>Descriptions (optional):</p>
        <input v-model="desMsg" placeholder="Type descriptions here" />
      <br>
      <br>
      <button class="btn btn-outline-primary" :disabled=disableBtn @click="sendData">Submit Conduct Record</button>
    </div>
  </div>

  <div v-if="responseMsg">
    <h4>{{ responseMsg }}</h4>
    <button type="button" name="newrecordbutton" class="btn btn-outline-primary" v-on:click="resetCls">Enter a new record</button>
  </div>

</template>

<script>
import DataService from '../database-handler'

export default {
  name: 'ConductView',

  data () {
    return {
      schYear: String(this.$schYear).slice(0,2) + '-' + String(this.$schYear).slice(2,4),
      termNo: this.$termNo,
      clsData: [],
      checkedNum: [],
      teacherName: '',
      conductItemsList: [],
      selectedConductItem: 0,
      desMsg: '',
      selectedCls: '',
      responseMsg: '',
      selectedConductType: ''
    }
  },

  methods: {
    showStudents (cls) {
      if (cls != '') {
        DataService.getClassData(cls)
          .then(response => {
            this.clsData = response.data
          })
          .catch(error => console.log(error))
        this.loadConductItems()
      }      
    },
    loadConductItems () {
      DataService.getConductItems()
        .then(response => {
          this.conductItemsList = response.data
        })
        .catch(error => console.log(error))
    },
    sendData () {
      const data = {
        teacherName: this.teacherName,
        cls: this.selectedCls,
        checkedData: this.checkedNum,
        conductCode: this.selectedConductItem,
        conductType: this.selectedConductType,
        desMsg: this.desMsg
      };
      DataService.submitConductRecord(data)
        .then(response => {
          console.log(response.data)
          this.responseMsg = response.data
        })
        .catch(error => {
          console.log(error)
          this.responseMsg = error
        })
      
    },
    resetCls () {
      this.selectedCls = ''
      this.clsData = []
      this.conductItemsList = []
      this.selectedConductItem = 0
      this.checkedNum = []
      this.desMsg = ''
      this.responseMsg = ''
      this.selectedConductType = ''
    },
  },

  beforeMount () {
    DataService.checkLogin({ token: sessionStorage.getItem("token") })
      .then(response => {
        this.teacherName = response.data[0]["Teacher"]
      })
      .catch(error => console.log(error))
  },

  computed: {
    disableBtn() {
      if (this.checkedNum.length && this.selectedConductItem) {
        return false
      } else {
        return true
      }
    },
    confirmedCls() {
      if (this.selectedCls && this.conductItemsList.length && !this.responseMsg) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
  .center {
    margin-left: auto;
    margin-right: auto;
  }
</style>