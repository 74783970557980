<template>
  <h2>Log In</h2>
  <GoogleLogin :callback="callback" />
  <br>
  <!--
  <h3 v-if="userName">Hello, {{ userName }}!</h3>
  <h3 v-if="userId">ID: {{ userId }}</h3>
  <h3 v-if="userHd">Domain: {{ userHd }}</h3>
  <h3 v-if="emailUserName">Username from email: {{ emailUserName }}</h3>
  <h3 v-if="rawResponse">Raw Response: {{ rawResponse }}</h3>
  <h3 v-if="decodedResponse">Decoded Response: {{ decodedResponse }}</h3>
  <h3 v-if="loggedInName">Logged In Name: {{ loggedInName }}</h3>
  -->
  <h3 v-if="errMsg">{{ errMsg }}</h3>
</template>

<script>
import DataService from '../database-handler'
import { decodeCredential } from 'vue3-google-login'

export default {
  name: "LoginView",

  data() {
    return {
      userName: '',
      userId: '',
      userHd: '',
      emailUserName: '',
      rawResponse: '',
      decodedResponse: '',
      loggedInName: '',
      errMsg: ''
    }
  },

  methods: {
    async callback(response) {

      const userData = decodeCredential(response.credential);

      this.userName = userData.name;
      this.userId = userData.sub;
      this.userHd = userData.hd;
      this.emailUserName = userData.email.substr(0, userData.email.length - userData.hd.length - 1);
      this.rawResponse = response.credential;
      this.decodedResponse = userData;

      // Check if the user is using teacher or student domain
      if (userData.hd == "teacher.hpccss.edu.hk" || userData.hd == "staff.hpccss.edu.hk") {
        const data = { temptoken: this.rawResponse };
        await DataService.updateToken(this.emailUserName, data)
          .then(response => {
            console.log("Response data: ",response.data);
            sessionStorage.setItem("token",this.rawResponse);
            /* let obj = sessionStorage.getItem("token");
            console.log("Session content: ",obj); */
          });

        let storedToken = sessionStorage.getItem("token");
        const jsonStored = { token: storedToken };
        DataService.checkLogin(jsonStored)
          .then(response => {
            this.loggedInName = response.data[0].Teacher;
            // console.log("User is logged in.")
            this.$router.push('/');
          })
          .catch(error => { console.log(error) })
      } else if (userData.hd == "student.hpccss.edu.hk") {
        sessionStorage.setItem("studentSID",this.emailUserName);
        this.$router.push('/studentview');
      } else {
        this.errMsg = "Something went wrong, please try again."
      }
    },
  },
}
</script>
