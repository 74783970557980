<template>
  <h2>Log Out</h2>
</template>

<script>
export default {
  name: "LogoutView",
 
  beforeMount () {
    sessionStorage.clear()
    this.$router.push('/login');
  },
}
</script>