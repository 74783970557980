<template>
  <h2>Enter ECA Record ({{ schYear }} Term {{ termNo }})</h2>
  <br>
  <router-link to="/">&lt;&lt; Back to Main</router-link>
  <br><br>
  <div v-if="!responseMsg">
    Logged in as: {{ teacherName }}
    <br><br>

    <div v-if="!confirmedCls">
      <label for="selectClass">Choose a class:</label>
      <select name="selectClass" v-model="selectedCls">
        <option value="1A">1A</option><option value="1B">1B</option><option value="1C">1C</option><option value="1D">1D</option><option value="1E">1E</option>
        <option value="2A">2A</option><option value="2B">2B</option><option value="2C">2C</option><option value="2D">2D</option><option value="2E">2E</option>
        <option value="3A">3A</option><option value="3B">3B</option><option value="3C">3C</option><option value="3D">3D</option><option value="3E">3E</option>
        <option value="4A">4A</option><option value="4B">4B</option><option value="4C">4C</option><option value="4D">4D</option><option value="4E">4E</option>
        <option value="5A">5A</option><option value="5B">5B</option><option value="5C">5C</option><option value="5D">5D</option><option value="5E">5E</option>
        <option value="6A">6A</option><option value="6B">6B</option><option value="6C">6C</option><option value="6D">6D</option><option value="6E">6E</option>
      </select>
      <br><br>
      <button type="button" name="showbutton" class="btn btn-primary" :disabled=confirmedCls v-on:click="showClassStudents(selectedCls)">Confirm Class</button>
    </div>

    <div v-if="confirmedCls">
      <h5>Class: {{ selectedCls }}</h5>
      <button type="button" name="resetbutton" class="btn btn-outline-secondary" v-on:click="resetCls">Reset Class</button>
      <br><br>
      <div class="row justify-content-center">
        <div class="col-10 col-md-8 col-lg-6">
          <div class="row">
            <label for="activityNameInput">Name of Activity:&emsp;</label>
            <input id="activityNameInput" class="col" v-model="activityName" placeholder="Type name of activity here" />
          </div>
          <div class="row">
            <label for="activityPostInput">Post:&emsp;</label>
            <input id="activityPostInput" class="col" v-model="activityPost" placeholder="Type post of activity here" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col col-md-10 col-lg-8">
          <table class="center table table-hover table-sm table-bordered">
            <thead class="thead-light">
              <tr>
                <th>Class</th>
                <th>Class No.</th>
                <th>English Name</th>
                <th>Chinese Name</th>
                <th>Checkbox</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in clsData" :key="index">
                <td>{{ item.Class }}</td>
                <td>{{ item.ClassNum }}</td>
                <td>{{ item.EngName }}</td>
                <td>{{ item.ChiName }}</td>
                <td><input type="checkbox" :id="item.Class+item.ClassNum" :value="item.ClassNum" v-model="checkedNum" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
      <button class="btn btn-outline-primary" :disabled=disableBtn @click="sendData">Submit Record</button>
    </div>
  </div>

  <div v-if="responseMsg">
    <h4>{{ responseMsg }}</h4>
    <br>
    <button type="button" name="newrecordbutton" class="btn btn-outline-primary" v-on:click="resetCls">Enter a new record</button>
  </div>

</template>

<script>
import DataService from '../database-handler'

export default {
  name: 'ClassView',

  data () {
    return {
      schYear: String(this.$schYear).slice(0,2) + '-' + String(this.$schYear).slice(2,4),
      termNo: this.$termNo,
      clsData: [],
      checkedNum: [],
      teacherName: '',
      activityName: '',
      activityPost: '',
      selectedCls: '',
      responseMsg: ''
    }
  },

  methods: {
    showClassStudents (cls) {
      if (cls != '') {
        DataService.getClassData(cls)
          .then(response => {
            this.clsData = response.data
          })
          .catch(error => console.log(error))
      }      
    },
    sendData () {
      const data = {
        teacherName: this.teacherName,
        cls: this.selectedCls,
        checkedData: this.checkedNum,
        activityName: this.activityName,
        activityPost: this.activityPost
      };
      DataService.submitEcaRecord(data)
        .then(response => {
          console.log(response.data)
          this.responseMsg = response.data
        })
        .catch(error => {
          console.log(error)
          this.responseMsg = error
        })
      
    },
    resetCls () {
      this.selectedCls = ''
      this.clsData = []
      this.checkedNum = []
      this.activityName = ''
      this.activityPost = ''
      this.responseMsg = ''
    },
  },

  beforeMount () {
    DataService.checkLogin({ token: sessionStorage.getItem("token") })
      .then(response => {
        this.teacherName = response.data[0]["Teacher"]
      })
      .catch(error => console.log(error))
  },

  computed: {
    disableBtn() {
      if (this.checkedNum.length) {
        return false
      } else {
        return true
      }
    },
    confirmedCls() {
      if (this.selectedCls && this.clsData.length && !this.responseMsg) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
  .center {
    margin-left: auto;
    margin-right: auto;
  }
</style>