import { createApp } from 'vue'
import App from './App.vue'
import createRouter from './router'
import vue3GoogleLogin from 'vue3-google-login'
import axios from 'axios'

const app = createApp(App)
app.use(createRouter())
app.use(vue3GoogleLogin, {
    clientId: '602272271230-bc687d66c5oadjaap2ks0m4niekk62go.apps.googleusercontent.com'
})
app.config.globalProperties.$axios = axios

// Set global variables for School Year and Term Number
app.config.globalProperties.$schYear = 2425
app.config.globalProperties.$termNo = 1
// Update above two values whenever necessary

app.mount('#app')
