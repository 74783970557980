<template>
  <h2>View Homework Late Submission Records ({{ schYear }})</h2>
  <br>
  Class:
  <br>
  <input v-model="stuClass" />
  <br>
  Class No.<br>(e.g. If class number is 2, enter 02):
  <br>
  <input v-model="stuClassNo" />
  <br>
  Please enter the first 4 digits of the student's HKID number:<br>
  (e.g. If HKID number is A123456(7), enter 1234)
  <br>
  <input v-model="hkidNum" />
  <br><br>
  <button class="btn btn-outline-primary" @click="sendData">Submit</button>
  <br>
  <div style="color:red;">{{ warningMsg }}</div>
  <br>
	<div v-if="!returnedHwkRecords.length && submitted">{{ returnedHwkRecords.message }}</div>
  <div v-if="!returnedHwkRecords.message" class="row justify-content-center">
    <div class="col-md-12 col-lg-10">
      <table class="center table table-hover table-sm table-bordered">
        <thead class="thead-light">
          <tr>
            <th v-for="(item, index) in returnedHwkRecords[0]" :key="index">{{ index }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in returnedHwkRecords" :key="index">
            <td v-for="(value, index) in item" :key="index">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DataService from '../database-handler'

export default {
  name: 'ParentPageView',

  data () {
    return {
      schYear: String(this.$schYear).slice(0,2) + '-' + String(this.$schYear).slice(2,4),
      returnedHwkRecords: [],
      stuClass: '',
      stuClassNo: '',
      hkidNum: '',
      combined: '',
      warningMsg: '',
      submitted: false,
    }
  },

  methods: {
    sendData () {
      if (this.stuClass.length == 2 && this.stuClassNo.length == 2 && parseInt(this.stuClassNo) > 0 && this.hkidNum.length == 4) {
        this.warningMsg = ''
        this.combined = this.stuClass + this.stuClassNo + this.hkidNum
        DataService.getParentHwkRecords(this.combined)
        .then(response => {
          this.returnedHwkRecords = response.data
          this.submitted = true
        })
        .catch(error => console.log(error))
        this.stuClass = ''
        this.stuClassNo = ''
        this.hkidNum = ''
        this.combined = ''
      } else {
        this.warningMsg = "Incorrect input format, please check."
        this.submitted = false
      }
    },
  }

}
</script>

<style scoped>
  .logoutbtn {
    color: red;
    background-color: rgb(255, 210, 210);
  }
  .logoutbtn:hover {
    background-color: rgb(255, 170, 170);
  }
</style>
