<template>
  <h2>Enter Late Record ({{ schYear }} Term {{ termNo }})</h2>
  <br>
  <router-link to="/">&lt;&lt; Back to Main</router-link>
  <br><br>
  <div v-if="!responseMsg">
    Logged in as: {{ teacherName }}
    <br><br>

    <div v-if="!confirmedCls">
      <label for="selectClass">Choose a class:</label>
      <select name="selectClass" v-model="selectedCls">
        <option value="1A">1A</option><option value="1B">1B</option><option value="1C">1C</option><option value="1D">1D</option><option value="1E">1E</option>
        <option value="2A">2A</option><option value="2B">2B</option><option value="2C">2C</option><option value="2D">2D</option><option value="2E">2E</option>
        <option value="3A">3A</option><option value="3B">3B</option><option value="3C">3C</option><option value="3D">3D</option><option value="3E">3E</option>
        <option value="4A">4A</option><option value="4B">4B</option><option value="4C">4C</option><option value="4D">4D</option><option value="4E">4E</option>
        <option value="5A">5A</option><option value="5B">5B</option><option value="5C">5C</option><option value="5D">5D</option><option value="5E">5E</option>
        <option value="6A">6A</option><option value="6B">6B</option><option value="6C">6C</option><option value="6D">6D</option><option value="6E">6E</option>
      </select>
      <br><br>
      <button type="button" name="showbutton" class="btn btn-primary" :disabled=confirmedCls v-on:click="showStudents(selectedCls)">Confirm Class</button>
    </div>

    <div v-if="confirmedCls">
      <h4>Class: {{ selectedCls }}</h4>
      <button type="button" name="resetbutton" class="btn btn-outline-secondary" v-on:click="resetCls">Reset Class</button>
      <br><br>
      
      <div class="row justify-content-center">
        <div class="col col-md-10 col-lg-8">
          <table class="center table table-hover table-sm table-bordered">
            <thead class="thead-light">
              <tr>
                <th>Class</th>
                <th>Class No.</th>
                <th>English Name</th>
                <th>Chinese Name</th>
                <th>Late Reasons</th>
                <th>Remarks (Optional)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in clsData" :key="index">
                <td>{{ item.Class }}</td>
                <td>{{ item.ClassNum }}</td>
                <td>{{ item.EngName }}</td>
                <td>{{ item.ChiName }}</td>
                <td><select v-model="checkedNum[item.ClassNum-1]">
                  <option v-for="(item, index) in lateReasons" :key="index" :value="index">{{item}}</option>
                </select></td>
                <td><input v-model="desMsg[item.ClassNum - 1]" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
      <button class="btn btn-outline-primary" :disabled=disableBtn @click="sendData">Submit Late Record</button>
    </div>
  </div>

  <div v-if="responseMsg">
    <h4>{{ responseMsg }}</h4>
    <br>
    <button type="button" name="newrecordbutton" class="btn btn-outline-primary" v-on:click="resetCls">Enter a new record</button>
  </div>

</template>

<script>
import DataService from '../database-handler'

export default {
  name: 'LateView',

  data () {
    return {
      schYear: String(this.$schYear).slice(0,2) + '-' + String(this.$schYear).slice(2,4),
      termNo: this.$termNo,
      clsData: [],
      checkedNum: [],
      teacherName: '',
      subjList: [],
      desMsg: [],
      selectedCls: '',
      responseMsg: '',
      confirmedCls: false,
      lateReasons: ['Not late','1. Traffic','2. Get up late','3. Sick','4. Others'],
    }
  },

  methods: {
    showStudents (cls) {
      if (cls != '') {
        DataService.getClassData(cls)
          .then(response => {
            this.clsData = response.data
            this.confirmedCls = true
            for (let i = 0; i < this.clsData[this.clsData.length - 1].ClassNum; i++) {
              this.desMsg[i] = ''
              this.checkedNum[i] = 0
            }
          })
          .catch(error => console.log(error))
      }      
    },
    sendData () {
      let checkedClsNum = []
      for (let i = 0; i < this.clsData[this.clsData.length - 1].ClassNum; i++) {
        if (this.checkedNum[i]) {
          checkedClsNum.push(i+1)
        }
      }
      const data = {
        teacherName: this.teacherName,
        cls: this.selectedCls,
        checkedData: checkedClsNum,
        desMsg: this.desMsg,
        itemCode: this.checkedNum
      };
      DataService.submitLateRecord(data)
        .then(response => {
          this.responseMsg = response.data
          console.log("Late record response: ",response.data)
        })
        .catch(error => {
          this.responseMsg = error
        })
    },
    resetCls () {
      this.selectedCls = ''
      this.clsData = []
      this.subjList = []
      this.selectedSubj = 0
      this.checkedNum = []
      this.desMsg = []
      this.responseMsg = ''
      this.confirmedCls = false
    },
  },

  beforeMount () {
    DataService.checkLogin({ token: sessionStorage.getItem("token") })
      .then(response => {
        this.teacherName = response.data[0]["Teacher"]
      })
      .catch(error => console.log(error))
  },

  computed: {
    disableBtn() {
      let sum = 0;
      for (let i = 0; i < this.checkedNum.length; i++) {
        if (this.checkedNum[i]) {
          sum += this.checkedNum[i]
        }
      }
      if (sum) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style>
  .center {
    margin-left: auto;
    margin-right: auto;
  }
</style>