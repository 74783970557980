<template>
  <h2>View All Homework Records ({{ schYear }})</h2>
  Logged in as: {{ studentSID }}
  <br><br>
	<div v-if="!returnedHwkRecords.length">If there are any records, they will be shown soon.</div>
  <div class="row justify-content-center">
    <div class="col-md-12 col-lg-10">
      <table class="center table table-hover table-sm table-bordered">
        <thead class="thead-light">
          <tr>
            <th v-for="(item, index) in returnedHwkRecords[0]" :key="index">{{ index }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in returnedHwkRecords" :key="index">
            <td v-for="(value, index) in item" :key="index">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
  </div>
  <center><router-link class="col-3 logoutbtn" to="/logout">Log Out</router-link></center>
</template>

<script>
import DataService from '../database-handler'

export default {
  name: 'StudentPageView',

  data () {
    return {
      schYear: String(this.$schYear).slice(0,2) + '-' + String(this.$schYear).slice(2,4),
      studentSID: '',
      returnedHwkRecords: []
    }
  },

  methods: {
    showStudentHwkRecords (sid) {
        sid = sid.toUpperCase()
        DataService.getStudentHwkRecords(sid)
        .then(response => {
          this.returnedHwkRecords = response.data
        })
        .catch(error => console.log(error))
      }
  },

  beforeMount () {
    if (sessionStorage.getItem("studentSID")) {
      this.studentSID = sessionStorage.getItem("studentSID")
      this.showStudentHwkRecords(this.studentSID)
    } else {
      this.$router.push('/login');
    }
  },
}
</script>

<style scoped>
  .logoutbtn {
    color: red;
    background-color: rgb(255, 210, 210);
  }
  .logoutbtn:hover {
    background-color: rgb(255, 170, 170);
  }
</style>
